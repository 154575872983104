import React, { FC } from 'react';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { AddToCartErrorType, FormError } from '../../../../types/errors';
import { Modal } from '../../Modal';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { mapToArray } from '../../../../utils';
import { CartErrorModalDatahooks } from './datahooks';
import { getErrorByType } from '../../../../utils/errors/errors';
import { useFormActions } from '../../Hooks/useFormActions';
import { CartFlow, FlowType } from '../../../../types/types';

export interface CartErrorModalProps {
  serviceData: ServiceData;
  errors: FormError[];
}

const CartErrorModal: FC<CartErrorModalProps> = ({ serviceData, errors }) => {
  const { t } = useTranslation();

  const { onClickViewCart, submitForm, onClickCloseCartErrorModal, onSubmit } =
    useFormActions();
  const addToCartError = getErrorByType({
    errorType: AddToCartErrorType,
    errors,
  });

  if (!addToCartError) {
    return null;
  }

  const serviceCount = mapToArray<SlotService>(serviceData.slotServices).length;

  let title: string, content: string;

  if (serviceCount > 1) {
    title = t(
      'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.multi-service-title',
      { count: serviceCount },
    );

    content = t(
      'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.multi-service-content',
    );
  } else {
    const serviceName = mapToArray<SlotService>(serviceData.slotServices)[0]
      .service.name;
    title = t(
      'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.title',
      { serviceName },
    );

    content = t(
      'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.content',
    );
  }

  return (
    <Modal
      modalTitle={title}
      modalSubtitle={content}
      primaryButton={{
        label: t(
          'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.primary-button',
        ),
        onClick: async () => {
          onClickCloseCartErrorModal();
          const submissionResponse = submitForm(true);

          if (submissionResponse) {
            onSubmit({
              submissionResponse,
              flowType: FlowType.SINGLE_SESSION,
              cartFlow: CartFlow.CHECKOUT,
            });
          }
        },
      }}
      secondaryButton={{
        label: t(
          'app.bookings-form.multi-location.cart-modal.service-could-not-be-added.secondary-button',
        ),
        onClick: () => onClickViewCart(),
      }}
      dataHook={CartErrorModalDatahooks.Modal}
      onClose={onClickCloseCartErrorModal}
    />
  );
};

export default CartErrorModal;
