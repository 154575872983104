import React from 'react';
import {
  TFunction,
  useTranslation,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentOptionSelection.st.css';
import { useFormActions } from '../../../../Hooks/useFormActions';
import { PaymentOptionsSelectionDataHooks } from './dataHooks';
import { PaymentOption as PaymentOptionComponent } from '../PaymentOption/PaymentOption';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../../../types/types';
import {
  SectionMessage,
  SectionMessageType,
} from '../../../SectionMessage/SectionMessage';
import { SlotService } from '../../../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../../../utils';

export type PaymentOptionSelectionProps = {
  slotService: SlotService;
  paymentOptions: PaymentOption[];
  isPricingPlanInstalled: boolean;
  selectedPaymentOptionId: string;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
};

export const PaymentOptionSelection: React.FC<PaymentOptionSelectionProps> = ({
  slotService,
  selectedPaymentOptionId,
  numberOfParticipants,
  dateRegionalSettingsLocale,
  paymentOptions,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { updateServiceSlot } = useFormActions();
  const { nestedSlot } = slotService;
  const key = getServiceSlotIdentifier(nestedSlot);

  const paymentOptionWarnings = getPaymentOptionWarnings({
    paymentOptions,
    numberOfParticipants,
    dateRegionalSettingsLocale,
    t,
  });

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={`${PaymentOptionsSelectionDataHooks.PAYMENT_OPTIONS_SELECTION}_${key}`}
    >
      {paymentOptionWarnings.map((sectionMessageText) => (
        <SectionMessage
          key={sectionMessageText}
          type={SectionMessageType.Alert}
          text={sectionMessageText}
        />
      ))}
      <div className={classes.paymentOptions}>
        {paymentOptions.map((paymentOption) => (
          <PaymentOptionComponent
            key={paymentOption.id!}
            id={paymentOption.id!}
            label={paymentOption.label!}
            selected={paymentOption.id === selectedPaymentOptionId}
            disabled={paymentOption.disabled!}
            suffix={paymentOption.suffix}
            validUntil={paymentOption.validUntil}
            onChange={() =>
              updateServiceSlot(
                { selectedPaymentOption: paymentOption },
                getServiceSlotIdentifier(nestedSlot),
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

const getPaymentOptionWarnings = ({
  paymentOptions,
  numberOfParticipants,
  dateRegionalSettingsLocale,
  t,
}: {
  paymentOptions: PaymentOption[];
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  t: TFunction;
}) => {
  let paymentOptionWarnings: string[] = [];
  const pricingPlanOptions = paymentOptions.filter(
    (option: PaymentOption) =>
      option.id !== ReservedPaymentOptionIds.BuyAPricingPlan &&
      option.id !== ReservedPaymentOptionIds.SingleSession,
  );
  const isMembershipPlan = (paymentOption: PaymentOption) =>
    !paymentOption.creditRemain;
  const isPunchCardWithoutEnoughCredit = (paymentOption: PaymentOption) =>
    Number(paymentOption?.creditRemain) < numberOfParticipants;

  const membershipWarningsPlanNames: string[] =
    numberOfParticipants > 1
      ? pricingPlanOptions
          .filter(isMembershipPlan)
          .map((membershiplan: PaymentOption) => membershiplan.label)
      : [];
  const punchCardWarningsPlanNames: string[] = pricingPlanOptions
    .filter(isPunchCardWithoutEnoughCredit)
    .map((punchCardPlan: PaymentOption) => punchCardPlan.label);

  paymentOptionWarnings = [
    ...(membershipWarningsPlanNames.length > 0
      ? [
          t(
            'app.payment.warning.membership-plan-reduce-participants-number.text',
            {
              numberOfPlans: membershipWarningsPlanNames!.length,
              planNames: formatPlanNames(
                membershipWarningsPlanNames,
                dateRegionalSettingsLocale,
              ),
            },
          ),
        ]
      : []),
    ...(punchCardWarningsPlanNames.length > 0
      ? [
          t(
            'app.payment.warning.not-enough-sessions-left-reduce-participants-number.text',
            {
              numberOfPlans: punchCardWarningsPlanNames!.length,
              planNames: formatPlanNames(
                punchCardWarningsPlanNames,
                dateRegionalSettingsLocale,
              ),
            },
          ),
        ]
      : []),
  ];
  return paymentOptionWarnings;
};

const formatPlanNames = (
  planNames: string[],
  dateRegionalSettingsLocale: string,
) => {
  const formatter = new Intl.ListFormat(dateRegionalSettingsLocale, {
    style: 'long',
    type: 'conjunction',
  });
  return formatter.format(planNames);
};
