import React from 'react';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentSectionContainerHook } from './dataHooks';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSelectionContainer.st.css';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { ServiceData } from '../../../../utils/state/types';
import { getMultiPaymentSelectionContainer } from './MultiPaymentSelectionContainer/MultiPaymentSelectionContainer';
import { getPaymentSelection } from './PaymentSelection/PaymentSelection';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { FormStatus } from '../../../../types/form-state';
import { FormError } from '../../../../types/errors';
import PriceOptionDropdownContainer from '../PriceOptionDropdownContainer/PriceOptionDropdownContainer';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export type PaymentSelectionContainerProps = {
  serviceData: ServiceData;
  memberDetails?: Member;
  numberOfParticipants: number;
  isPricingPlanInstalled: boolean;
  status: FormStatus;
  errors: FormError[];
};

export const PaymentSelectionContainer: React.FC<
  PaymentSelectionContainerProps
> = (props) => {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();

  const { dateRegionalSettingsLocale } = useFormComponentContext();

  const settingsParamForTitle = settingsParams.selectPaymentTitle;
  const title = getContent({
    settings,
    settingsParam: settingsParamForTitle,
  });

  let componentToShow = null;
  if (
    !props.serviceData.isSingleService ||
    experiments.enabled(ExperimentsConsts.FormPaymentOptionNewDropdown)
  ) {
    componentToShow = getMultiPaymentSelectionContainer({
      ...props,
      dateRegionalSettingsLocale,
    });
    return (
      componentToShow && (
        <fieldset
          className={st(classes.root, { isMobile })}
          data-hook={PaymentSectionContainerHook.Payment_Section}
        >
          <SectionTitle label={title} tagName={TagName.Legend} />
          {componentToShow}
        </fieldset>
      )
    );
  } else {
    const paymentSelection = getPaymentSelection({
      ...props,
      dateRegionalSettingsLocale,
    });
    return (
      <>
        {paymentSelection && (
          <fieldset
            className={st(classes.root, { isMobile })}
            data-hook={PaymentSectionContainerHook.Payment_Section}
          >
            <SectionTitle label={title} tagName={TagName.Legend} />
            {paymentSelection}
          </fieldset>
        )}
        <PriceOptionDropdownContainer
          serviceData={props.serviceData}
          errors={props.errors}
          status={props.status}
        />
      </>
    );
  }
};
